<template>
     <b-container fluid="xxl">
        <b-row class="mb-4">
            <b-col cols="12" >
                <b-button
                type="button"
                variant="cta"
                class="button-cta rounded-circle icon"
                :to="'/academylessons'"
                ><font-awesome-icon icon="fa-solid fa-caret-left" /></b-button>

            </b-col>

        </b-row>

        <b-row>
            <b-col cols="12" md="8">
                <h6 class="font-light">{{ $t('ACADEMY') }}</h6> 
                <h3>{{productTitle}}</h3>
                <p class="mb-5">{{productDescription}}</p>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12">
                <div  class="position-relative">

                    <div class="wl-videolesson__overlay d-flex flex-column align-items-center justify-content-center" :class="showOverlay ? 'wl-videolesson__overlay--active': ''">

                        <div class="text-center" v-for="(next, index) in nextLesson" :key="index">
                       
                            <h6 class="font-light">{{ $t('NEXT_LESSON') }}</h6>
                            <h4 class="mb-3">{{next.lesson_title}}</h4>
                            <div class="wl-videolesson__overlay__cta" @click="handleProgressClickEvent">
                                <b-img :src="next.lesson_poster" fluid class="wl-videolesson__overlay__thumbnail"></b-img>
                                <div class="wl-videolesson__overlay__timer">
                                    <uiCircularTimer :stroke-color="loaderStyle" ref="nextLesson" v-on:finished="handleProgessFinishEvent"/>
                                </div>
                            </div>
                        </div>

                    </div>
                    <template v-if="getType == 'Video'">
                        <div v-for="(lesson, index) in getCurrentLesson" :key="index">
                            <uiVideoPlayer ref="vplayer"
                                :videoid="lesson.lesson_id"
                                :should-play.sync="isPlaying"
                                :ref-hash.sync="lesson.hash"
                                :use-call-back="true"
                                :currenttime.sync="lesson.lesson_current_time"
                                v-on:update="updateLesson"
                                v-on:ready="initLesson"
                                v-on:played="onPlaying"
                                v-on:paused="onPaused"
                                v-on:ended="onEnded"
                                :video.sync="lesson.lesson_src" 
                                :poster.sync="lesson.lesson_poster"
                                controls="~@/assets/mejs-controls.svg"
                            />
                        </div>
                    </template>

                    <template v-if="getType == 'Audio'">
                        <div v-for="(lesson, index) in getCurrentLesson" :key="index">
                            <uiAudioPlayer ref="aplayer"
                                :audioid="lesson.lesson_id"
                                :should-play.sync="isPlaying"
                                :ref-hash.sync="lesson.hash"
                                :use-call-back="true"
                                :currenttime.sync="lesson.lesson_current_time"
                                v-on:update="updateLesson"
                                v-on:ready="initLesson"
                                v-on:played="onPlaying"
                                v-on:paused="onPaused"
                                v-on:ended="onEnded"
                                :audio.sync="lesson.lesson_src"
                                :poster.sync="lesson.lesson_poster"
                            />
                        </div>
                    </template>
                </div>
            </b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col cols="12">
                
                <b-tabs active class="wl-tabs wl-tabs__videotabs">

                
                    
                     <b-tab :title="$t('DESCRIPTION')">
                        <b-container fluid="xxl">
                            <b-row class="mb-7 mt-3" v-for="(lesson, index) in getCurrentLesson" :key="index">
                                <b-col cols="12">
                                    <div class=" mt-3" v-html="lesson.lesson_description"></div>
                                </b-col>
                            </b-row>
                        </b-container>
                       
                    </b-tab>
                    
                    
                    <b-tab :title="$t('NOTES')"  class="position-relative">
                        <b-container fluid="xxl">
                            <b-row class="mb-7 mt-3" v-for="(lesson, index) in getCurrentLesson" :key="index">
                                <b-col cols="12" xl="8">
                                    <div class="d-flex align-items-center w-100">

                                        <div class="p-4">
                                            <b-button
                                                type="button"
                                                variant="cta"
                                                class="button-cta rounded-circle icon small"
                                                @click="updateNotice(lesson.lesson_id)"
                                            >
                                            <font-awesome-icon :icon="(isEditNotice) ? 'fa-solid fa-check': 'fa-solid fa-pencil'" /></b-button>
                                        </div>
                                        
                                        <div class="w-100">
                                            
                                            <p class="mb-0" v-if="!isEditNotice"><small>{{ handleNotice }}</small></p>
                                            <div v-else>
                                                <b-input-group>

                                                <b-form-textarea
                                                    name="lessonNotice"
                                                    id="lessonNotice"
                                                    type="text"
                                                    size="sm"
                                                    :placeholder="$t('INSERT_YOUR_NOTES')"
                                                    rows="8"
                                                    v-model.trim="handleNotice"
                                                ></b-form-textarea>

                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-tab>
                </b-tabs>
                
            </b-col>
       </b-row>

       <b-row
           v-if="'upselling' in this.getProduct && this.getProduct.upselling.length > 0"
           class="justify-content-md-center mb-3 mt-5">
         <b-col
             cols="12">

           <h3>{{$t('PRODUCT_RECOMMONDATIONS')}}</h3>

           <b-row>
             <!--{{upselling}}-->
             <b-col
                 v-for="(product, product_index)  in this.getProduct.upselling"
                 :key="product_index"
                 cols="12" xl="6" xxl="4"
                 class="mt-4 mb-3"
             >
               <ProductCard
                   :no-padding="true"
                   :product="product"
                   :product-id="product.product_id  | tostring"
                   :product-title="product.product_name"
                   :product-description="product.product_description_short"
                   :product-type="product.product_type"
                   :badge-text="badgeTextHandler(product)"
                   :product-price="`${product.product_price}`"
                   :product-pseudo-price="`${product.product_pseudoprice}`"
                   :product-btn-label="handleSliderProductBtnLabel(product)"
                   :product-image="$utils.getMediaImg(3, product.media, 'product_media_description_type_id')"
                   :product-cta-link="product.product_url"

                   v-on:submit="handleSliderProductCardEvent"

                   :small-layout="true"


               />

             </b-col>
           </b-row>

         </b-col>
       </b-row>

       <b-modal size="xl" ref="promotion-modal" hide-footer :title="promotionModalTitle">
         <div class="d-block text-left">
           <p v-html="promotionModalContent"></p>
         </div>
       </b-modal>

     </b-container>
</template>


<script>
    import {mapActions, mapGetters} from "vuex";
    import uiVideoPlayer from '@/components/ui/uiVideoPlayer';
    import uiAudioPlayer from '@/components/ui/uiAudioPlayer';
    import uiCircularTimer from "./ui/uiCircularTimer.vue";
    import ProductCard from "@/components/ProductCard.vue";
    import axios from "axios";
    import Vue from "vue";

    export default {
        name: "areaAcademyVideo",
        components: {
          ProductCard,
           uiVideoPlayer,
            uiCircularTimer,
            uiAudioPlayer
        },
        data() {
            return {
              API_URL: process.env.VUE_APP_API,
              showOverlay: false,
              cancleTimer:false,
              isEditNotice: false,
              lessonNotice: '',

              upselling: [],
              promotionModalTitle: '',
              promotionModalContent: '',
              chatScript: null,
            }
        },
        mounted() {

            if (!this.$route.params.video_id) {
              this.$router.push('/academylessons');
            } else {
              this.loadCourse({id: this.$route.params.video_id});
              this.lessonNotice = this.handleNotice;
              /*
              if ("upselling" in this.getProduct && this.getProduct.upselling.length > 0) {
                this.upselling = this.getProduct.upselling;
              }
               */

            }

            
        },
        computed: {
        ...mapGetters( {
                getProduct : 'getProduct',
                isPlaying: 'getCurrentLessonPlaying',
                getCurrentLesson: 'getCurrentLesson',
                nextLesson : 'getNextLesson'
            }),
            loaderStyle() {
                const root = document.querySelector(':root');
                return getComputedStyle(root).getPropertyValue('--cta')
                
            },

            productTitle: {
                get() {
                    return this.getProduct.product_title
                }
            },
            productDescription: {
                get() {
                    return this.getProduct.product_description
                }
            },
            getType : {
                get() {
                    return this.getCurrentLesson[0]?.type 
                }
            },

            handleNotice : {
                get() {
                    return this.getCurrentLesson[0]?.notice || this.$t('INSERT_YOUR_NOTES')
                },

                set(value) {
                   this.lessonNotice = value
                }
            }

        },
        beforeDestroy() {
          this.cleanupChatWidget();
        },
        methods: {
          ...mapActions([
                'loadCourse',
                'updateCurrentLesson',
                'setCurrentLessonPlaying',
                'setCurrentLesson',
                'checkLessonCompletions',
                'updateLessonNotice'
          ]),

          initLesson() {
              this.showOverlay = false;
              const timer  = this.$refs.nextLesson[0];
              timer.stop();
              this.lessonNotice = this.handleNotice;
          },

          updateLesson(data) {
                this.updateCurrentLesson(data)
          },
          onPlaying() {
                this.setCurrentLessonPlaying(true)
          },
          onPaused(data) {
                this.updateCurrentLesson(data)
          },
          handleProgessFinishEvent() {
              this.showOverlay = false;
              this.setCurrentLesson({shouldplay: true})

          },
          onEnded(data) {
              this.checkLessonCompletions(data)
              this.setCurrentLessonPlaying(false);
              this.showOverlay = true;
              const timer  = this.$refs.nextLesson[0];
              timer.start();
          },
          handleProgressClickEvent(){

              this.showOverlay = false;
              const timer  = this.$refs.nextLesson[0];
              timer.stop();
              this.setCurrentLesson({shouldplay: true})
          },

          updateNotice(lesson_id) { 
            const timer  = this.$refs.nextLesson[0];
            if (!this.isEditNotice) {
               
                switch(this.getType) {
                    case 'Audio': {
                        const {aplayer} = this.$refs;
                        aplayer[0].setPause();
                        break;
                    }
                     
                    
                    case 'Video': {
                        const {vplayer} = this.$refs;
                        vplayer[0].setPause();
                        break;
                    }
                      
                }
                if(this.showOverlay) timer.stop()
                this.isEditNotice = true;
            } else {
                this.isEditNotice = false;
                this.updateLessonNotice({id: lesson_id, text: this.lessonNotice})                
                if(this.showOverlay) timer.start()

            }
           

          },
          reloadCourse() {
            this.loadCourse({id: this.$route.params.video_id});
          },

          async handleSliderProductCardEvent(data) {
            if(!data || !("type" in data) ) {
              return;
            }

            var url = '';
            var purl = '';
            //console.log(data);
            //console.log(data.type);

            switch(data.type) {

              case 'Whitepaper':
                await this.$root.$emit('track', {source: null, target: data.product['whitepaper'].media[0].url, button: data.type + ': ' + data.product.product_name});
                //console.log(data.product['whitepaper'].media[0].url);
                await this.trackSale(data.id, data.product['whitepaper'].media[0].url);
                break;

              case 'Checkliste':
                if (("product" in data)
                    && data.product.product_price > 0
                ) {
                  await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
                  //zur Kaufseite
                  purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
                  window.open(purl,'_blank');
                } else {
                  url = '/myChecklistProjects/' + data.product.product_id;
                  await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
                  //Gratisliste erwerben
                  await this.trackSaleChecklistUpselling(data.product.product_id, url);
                }
                break;

              case 'Onlinekurs':
                if (("product" in data)
                    && data.product.product_price > 0
                ) {
                  await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
                  //zur Kaufseite
                  purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
                  window.open(purl,'_blank');
                } else {
                  url = '/academylesson/' + data.product.product_id;
                  await this.$root.$emit('track', {source: null, target: url, button: data.type + ': ' + data.product.product_name});
                  //Gratis erwerben
                  await this.trackSaleCourse(data.product.product_id, url);
                }
                break;

              case 'Support':
              case 'Mastermind':
                /*
                if (("product" in data)
                    && data.product.product_price > 0
                ) {
                  await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
                  //zur Kaufseite
                  purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
                  window.open(purl,'_blank');
                }
                */

                if (("product" in data)) {

                  if (data.product.product_is_promotionpage) {

                    await this.$root.$emit('track', {source: null, target: 'promotionpage', button: data.type + ': ' + data.product.product_name});

                    await this.promotion(data.product.product_id);

                  } else if (data.product.product_url != '' && data.product.product_url != null) {

                    await this.$root.$emit('track', {source: null, target: data.product.product_url, button: data.type + ': ' + data.product.product_name});
                    //zur Kaufseite
                    purl = this.$utils.checkIfUrlAndAddParam(data.product.product_url, this.getTokenParamWithTokenMd5);
                    window.open(purl,'_blank');

                  }
                }
                break;
            }


          },
          async promotion(product_id) {

            //console.log(item);

            //todo: local development
            var cookie = this.$store.getters.getToken;

            const response = await axios.post(
                this.API_URL,
                {
                  type: 'get_preview_promotion',
                  product_id: product_id,
                  cookie: cookie
                }
            );

            //console.log(response.data);

            this.promotionModalTitle = response.data.product_promotion_title;
            this.promotionModalContent = response.data.product_promotion_description;

            this.$refs['promotion-modal'].show();
          },
          async trackSale(product_id, url) {

            //todo: local development
            var cookie = this.$store.getters.getToken;

            await axios.post(
                this.API_URL,
                {
                  type: 'track_sale',
                  cookie: cookie,
                  product_id: product_id,
                }
            );

            this.$store.dispatch('updateMyProductIsMyProduct', product_id);
            this.emitChanges = true;
            //console.log(url);
            var purl = this.$utils.checkIfUrlAndAddParam(url, this.getTokenParamWithTokenMd5);
            window.location = purl;


          },

          async trackSaleChecklistUpselling(product_id, url) {

            //todo: local development
            var cookie = this.$store.getters.getToken;

            await axios.post(
                this.API_URL,
                {
                  type: 'track_sale_checklist',
                  cookie: cookie,
                  product_id: product_id,
                }
            );

            this.$store.dispatch('updateMyProductIsMyProduct', product_id);
            //this.$router.push('/myChecklistProjects/' + product_id);
            //this.$router.push(url);

            this.productID = product_id;
            this.$router.push(url);

            //this.$router.push({path: '/myChecklistProjects/', params: { product_id: product_id}});

          },

          async trackSaleCourse(product_id, url) {

            //todo: local development
            var cookie = this.$store.getters.getToken;

            await axios.post(
                this.API_URL,
                {
                  type: 'track_sale_course',
                  cookie: cookie,
                  product_id: product_id,
                }
            );

            //this.$router.push('/academylesson/' + product_id);
            this.$router.push(url);

          },
          badgeTextHandler(product) {

            //console.log(product);

            var label = '';

            if (product.product_price > 0) {
              label = Vue.utils.formatPrice(product.product_price);
            } else {
              label = this.$t('FREE');
            }

            return label;

          },
          handleSliderProductBtnLabel(product) {

            //console.log(product);

            var label = '';

            switch(product.product_type) {
              case 'Whitepaper':
                label = this.$t('FREE_OF_CHARGE_DOWNLOAD');
                break;

              case 'Checkliste':
                if (product.product_price > 0) {
                  /*
                  label = '';
                  if (product.product.product_pseudoprice > 0) {
                    label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
                  }
                  label += Vue.utils.formatPrice((product.product.product_price));

                   */
                  label = this.$t('MORE_DETAILS');
                } else {
                  label = this.$t('FREE_OF_CHARGE_ACCESS');
                }
                break;

              case 'Onlinekurs':
                if (product.product_price > 0) {
                  /*
                  label = '';
                  if (product.product.product_pseudoprice > 0) {
                    label = "<s>" + Vue.utils.formatPrice((product.product.product_pseudoprice)) + "</s>";
                  }
                  label += Vue.utils.formatPrice((product.product.product_price));

                   */
                  label = this.$t('MORE_DETAILS');
                } else {
                  label = this.$t('GO_TO_LEARNING_CONTENT');
                }
                break;

              case 'Support':
                label = this.$t('MORE_DETAILS');
                break;

              case 'Mastermind':
                label = this.$t('MORE_DETAILS');
                break;


            }

            //console.log(label, product.product_name);

            return label;


          },
          loadChatWidget() {
            if (window.ChatWidgetConfig) return; // Falls schon geladen

            window.ChatWidgetConfig = {
              chatId: this.getProduct.chat_widget.chat_id,
              title: this.getProduct.chat_widget.chat_title,
              welcomeMessage: this.getProduct.chat_widget.chat_welcome_message,
              primaryColor: this.getProduct.chat_widget.chat_primary_color,
              //primaryColor: '#51388E',
              position: this.getProduct.chat_widget.chat_position,
              apiEndpoint: this.getProduct.chat_widget.chat_api_endpoint,
              debug: this.getProduct.chat_widget.chat_debug,
            };

            this.chatScript = document.createElement("script");
            this.chatScript.src = "/chat-widget.js";
            this.chatScript.async = true;
            document.body.appendChild(this.chatScript);
          },
          cleanupChatWidget() {
            console.log('cleanupChatWidget');
            if (this.chatScript) {
              document.body.removeChild(this.chatScript);
              this.chatScript = null;
            }
            delete window.ChatWidgetConfig; // Konfigurationsobjekt entfernen

            //Chat-Widget-Container entfernen
            const chatWidget = document.querySelector(".chat-widget-container");
            if (chatWidget) {
              chatWidget.remove();
            }
          }

        },
      watch: {
        getProduct(newValue) {
          if (newValue && newValue.chat_widget) {
            this.loadChatWidget();
          }
        }
      }

    }
</script>

<style scoped>

.card-footer {
  background-color: white;
}
</style>